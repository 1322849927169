import { render, staticRenderFns } from "./BioDataBuilder.vue?vue&type=template&id=5fdfd002&scoped=true&"
import script from "./BioDataBuilder.vue?vue&type=script&lang=js&"
export * from "./BioDataBuilder.vue?vue&type=script&lang=js&"
import style0 from "./BioDataBuilder.vue?vue&type=style&index=0&id=5fdfd002&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fdfd002",
  null
  
)

export default component.exports