<template>
  <v-row>
    <v-col cols="12" class="pa-0">
      <v-breadcrumbs
        class="pl-0 primary--text"
        :items="navigator"
        divider="/"
      ></v-breadcrumbs>
    </v-col>
    <v-col class="pb-1 pt-1" cols="12" style="border-bottom: 1px solid #ff6700">
      <strong class="primary--text">Bio Data Builder</strong>
    </v-col>
    <v-col cols="12" lg="7" class="px-0">
      <v-stepper v-model="e6" vertical flat>
        <v-stepper-step :complete="e6 > 1" step="1">
          Select a Template
          <small class="mt-1">Please select a template to proceed</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-card class="mb-5" height="200px"></v-card>
          <v-btn color="primary" @click="e6 = 2"> Continue </v-btn>
          <v-btn text> Cancel </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">
          Personal Information
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-card class="mb-5" height="200px"></v-card>
          <v-btn color="primary" @click="e6 = 3"> Continue </v-btn>
          <v-btn text> Cancel </v-btn>
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 3" step="3">
          Education & Achievements
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-card class="mb-5" height="200px"></v-card>
          <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
          <v-btn text> Cancel </v-btn>
        </v-stepper-content>

        <v-stepper-step step="4"> Work Experience </v-stepper-step>
        <v-stepper-content step="4">
          <v-card class="mb-5" height="200px"></v-card>
          <v-btn color="primary" @click="e6 = 1"> Continue </v-btn>
          <v-btn text> Cancel </v-btn>
        </v-stepper-content>
      </v-stepper>
    </v-col>
    <v-col cols="12" lg="5" class="pr-0">
      <v-card flat height="80vh">
        <v-card-text
          class="d-flex justify-center align-center flex-wrap"
          style="height: 100%"
        >
          <h2>Select Template to Preview</h2>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      e6: 1,
      navigator: [
        {
          text: "Builder Tools",
          disabled: false,
          href: "",
        },
        {
          text: "Bio Data Builder",
          disabled: true,
          href: "",
        },
      ],
    };
  },
};
</script>
<style scoped>
.theme--dark.v-stepper {
    background: inherit !important
}
</style>
